body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

/* Not sure where else to override this style */
.react-datepicker__time-list {
  padding: 0;
  overflow-y: unset !important;
}
.react-datepicker__header__dropdown--select {
  margin-top: 5px;
}

/* For the deletion modal in View Incident*/

.deleteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 2%;
  background-color: white;
  border: 1px solid rgb(204, 204, 204);
}

.absModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 2%;
  background-color: white;
}

.MuiTablePagination-spacer {
  flex: none !important;
}

label + .MuiInput-formControl {
  margin-top: 0 !important;
}

.MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #091f3e !important;
}


